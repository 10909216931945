import { css } from '@emotion/core'
import styled from '@emotion/styled-base'
import { Link, navigate } from 'gatsby'
import { findIndex, flatten, groupBy, mapObjIndexed, prop, remove } from 'ramda'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Subscribe } from 'unstated'

import green from '@material-ui/core/colors/green'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MobileStepper from '@material-ui/core/MobileStepper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import SavedIcon from '@material-ui/icons/CheckCircle'
import HelpIcon from '@material-ui/icons/Help'

import DropboxIcon from '../components/dropbox-icon'
import SEO from '../components/seo'
import { SAVINGS_COMPONENTS, WELCOME_ACCOUNTS } from '../constants'
import SavingsContainer from '../containers/SavingsContainer'
import SettingsContainer from '../containers/SettingsContainer'

import logo from '../images/reachfi-logo.svg'

import withRoot from '../withRoot'

const CenteredOnboardingStep = ({ children }) => (
  <OnboardingStep
    containerCss={{ alignItems: 'center' }}
    wrapperCss={{ textAlign: 'center', maxWidth: '80%' }}
  >
    {children}
  </OnboardingStep>
)

const OnboardingStep = ({ children, containerCss = '', wrapperCss = '' }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      height: 100%;
      ${containerCss}
    `}
  >
    <div
      css={css`
        flex: none;
        width: 100%;
        ${wrapperCss}
      `}
    >
      {children}
    </div>
  </div>
)

const OnboardingWelcome = () => (
  <CenteredOnboardingStep>
    <Grow in>
      <img
        src={logo}
        alt="Reach FI logo"
        css={{ maxHeight: 250, marginBottom: 24 }}
      />
    </Grow>
    <Fade in>
      <div>
        <Typography variant="h4" gutterBottom>
          Welcome to Reach FI!
        </Typography>
        <Typography variant="subtitle1" css={{ marginBottom: 48 }}>
          Reach FI can help you track your progress and keep you motivated on
          your path to financial independence
        </Typography>

        <Typography variant="subtitle2" gutterBottom>
          Next, we'll quickly personalize the app for your situation
        </Typography>
      </div>
    </Fade>
  </CenteredOnboardingStep>
)

class CategoryItem extends React.Component {
  state = { helpText: null }

  toggleHelp = () => {
    this.setState(prevState => ({
      helpText: prevState.helpText ? null : this.props.item.description,
    }))
  }

  render() {
    const {
      item: { name, description },
      onToggle,
      checked,
    } = this.props
    return (
      <ListItem
        button
        onClick={() => onToggle(this.props.item)}
        role={undefined}
        data-testid={`item-${name}`}
      >
        <Checkbox
          tabIndex={-1}
          disableRipple
          disableTouchRipple
          checked={checked}
        />
        <ListItemText secondary={this.state.helpText ? description : null}>
          {name}
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton
            aria-label="What is this?"
            onClick={this.toggleHelp}
            data-testid={`help-${name}`}
          >
            <HelpIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }
}
CategoryItem.defaultProps = {
  item: null,
  checked: false,
  onToggle(acc) {},
}

const OnboardingChooseCategories = ({
  onAccountToggle,
  selectedAccountTypes,
}) => {
  const Header = styled('div')`
    background: ${props => props.theme.palette.primary.main};
    padding: 24px;
  `

  return (
    <OnboardingStep>
      <Header>
        <Typography variant="h6">
          Which of these account types do you contribute to?
        </Typography>
      </Header>
      <List>
        {WELCOME_ACCOUNTS.map(item => (
          <CategoryItem
            key={item.name}
            item={item}
            onToggle={onAccountToggle}
            checked={!!selectedAccountTypes.find(acc => acc.name === item.name)}
          />
        ))}
      </List>
    </OnboardingStep>
  )
}
OnboardingChooseCategories.defaultProps = {
  onAccountToggle(acc) {
    return
  },
  selectedAccountTypes: [],
}

const OnboardingDone = ({ done, fromUrl }) => (
  <CenteredOnboardingStep>
    <Grow in={done}>
      <SavedIcon
        css={css`
          font-size: 9em;
          color: ${green[500]};
        `}
      />
    </Grow>
    <Typography variant="h4" gutterBottom data-test-done>
      All set!
    </Typography>
    <Typography variant="subtitle1">
      Your choices help figure out what information to show around the app.
    </Typography>
    <Typography variant="caption" css={{ marginTop: 24 }}>
      You can change your preferences anytime under Settings.
    </Typography>
    <div css={{ marginTop: 24 }}>
      <div>
        <Button
          component={Link}
          variant="contained"
          css={{ marginBottom: 16 }}
          to="/integrations/dropbox"
          replace
          data-testid="btn-dropbox"
        >
          <DropboxIcon style={{ marginRight: 8 }} />
          Connect to Dropbox
        </Button>
      </div>
      <div>
        <Button
          component={Link}
          variant="contained"
          color="secondary"
          css={{ marginBottom: 16 }}
          to="/settings/line-items"
          replace
          data-testid="btn-customize"
        >
          Customize savings
        </Button>
      </div>
      <div>
        <Button
          component={Link}
          to={fromUrl || '/'}
          replace
          size="small"
          data-testid="btn-home"
        >
          or jump into the app
        </Button>
      </div>
    </div>
  </CenteredOnboardingStep>
)

const OnboardingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.palette.primary.light};

  .stepper {
    background: ${props => props.theme.palette.secondary.main};

    .left-button:not(:disabled),
    .right-button:not(:disabled) {
      color: ${props => props.theme.palette.secondary.contrastText};
    }

    .dot-active {
      background: ${props => props.theme.palette.secondary.light};
    }
  }
`

const MAX_STEPS = 3
const LAST_STEP_INDEX = MAX_STEPS - 1

class OnboardingScreen extends React.Component {
  state = {
    done: false,
    activeStep: 0,
    selectedAccountTypes: [],
  }

  componentDidUpdate() {
    if (this.props.onboarded && !this.state.done) {
      navigate('/')
    }
  }

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }))
  }

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }))
  }

  handleStepChange = activeStep => {
    this.setState({ activeStep })
  }

  handleStepTransitionEnd = () => {
    if (this.state.activeStep === LAST_STEP_INDEX) {
      this.setState({ done: true }, () => {
        this.handleOnboardCompleted()
      })
    } else {
      this.setState({ done: false })
    }
  }

  handleOnboardCompleted = () => {
    // Set default line items
    const { selectedAccountTypes } = this.state

    if (selectedAccountTypes.length) {
      const components = mapObjIndexed(
        lineItems => flatten(lineItems),
        groupBy(
          prop('category'),
          flatten(selectedAccountTypes.map(acc => acc.lineItems))
        )
      )

      const mergedComponents = {
        S1: [],
        S2: [],
        S3: [],
        C: SAVINGS_COMPONENTS.C.defaultLineItems,
        D: SAVINGS_COMPONENTS.D.defaultLineItems, // TODO: situations like insurance, commuter
        T: SAVINGS_COMPONENTS.T.defaultLineItems,
        ...components,
      }

      this.props.setDefaultComponents(mergedComponents)
    }
    this.props.setOnboardedSuccess()
  }

  handleAccountToggle = account => {
    const existingAccount = findIndex(
      acc => acc.name === account.name,
      this.state.selectedAccountTypes
    )

    if (existingAccount > -1) {
      const updatedAccounts = remove(
        existingAccount,
        1,
        this.state.selectedAccountTypes
      )
      this.setState({ selectedAccountTypes: updatedAccounts })
    } else {
      this.setState(prevState => ({
        selectedAccountTypes: [...prevState.selectedAccountTypes, account],
      }))
    }
  }

  render() {
    const { classes, theme } = this.props
    const { activeStep } = this.state

    return (
      <OnboardingContainer>
        <SwipeableViews
          css={{ flex: 1 }}
          containerStyle={{ height: '100%' }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          onTransitionEnd={this.handleStepTransitionEnd}
          enableMouseEvents
        >
          <OnboardingWelcome />
          <OnboardingChooseCategories
            selectedAccountTypes={this.state.selectedAccountTypes}
            onAccountToggle={this.handleAccountToggle}
          />
          <OnboardingDone done={this.state.done} fromUrl={this.props.fromUrl} />
        </SwipeableViews>
        <MobileStepper
          steps={MAX_STEPS}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          classes={{ root: 'stepper', dotActive: 'dot-active' }}
          nextButton={
            <Button
              data-testid="next"
              className="right-button"
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === LAST_STEP_INDEX}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              data-testid="prev"
              className="left-button"
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </OnboardingContainer>
    )
  }
}
OnboardingScreen.defaultProps = {
  onboarded: false,
  fromUrl: '/',
  setOnboardedSuccess() {},
  setDefaultComponents(components) {},
}

const ThemedOnboardingScreen = withStyles(theme => ({}), { withTheme: true })(
  OnboardingScreen
)

const WelcomePage = ({ location: { state = { fromUrl: '/' } } }) => (
  <>
    <SEO
      title={'Welcome to Reach FI'}
      keywords={[
        `fi`,
        `savings rate`,
        `calculator`,
        `personal finance`,
        `financial independence`,
      ]}
    />

    <Subscribe to={[SavingsContainer, SettingsContainer]}>
      {(savingsData, settings) => (
        <ThemedOnboardingScreen
          onboarded={settings.state.onboarded}
          fromUrl={state ? state.fromUrl : '/'}
          setOnboardedSuccess={settings.setOnboardedSuccess}
          setDefaultComponents={savingsData.setDefaultComponents}
        />
      )}
    </Subscribe>
  </>
)

export default withRoot(WelcomePage)
